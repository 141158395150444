#model3 .member {
  margin: 20px 0px;
  border-radius: 15px;
  padding: 40px 0;
  height: max-content;
  background-color: #fff;
  transition: all 1s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 0 0 30%;
  cursor: pointer;
  margin-left: 35px;
}
#model4 .member {
  margin: 20px 0px;
  border-radius: 15px;
  padding: 40px 0;
  height: max-content;
  background-color: #fff;
  transition: all 1s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 0 0 15%;
  cursor: pointer;
  margin-left: 35px;
}

#model3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
#model3 .member::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 0;
  height: 20px;
  transition: all 1s;
  background: #cb9903;
}

#model3 .member::before {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: 0;
  height: 20px;
  transition: all 1s;
  background: #cb9903;
}

#model3 img {
  border-radius: 50%;
  transition: all 1s;
}

.img {
  border-radius: 50%;
  transition: all 1s;
  justify-content: center;
}
#model3 .description h1 {
  color: rgb(0, 0, 0);
  text-align: center;
}

#model3 .description h2 {
  font-family: "Poppins", sans-serif;
  text-align: center;
  color: #000000;
  font-weight: 600;
  font-size: 18px;
  margin-top: -15px;
}

#model3 .description p {
  color: rgb(0, 0, 0);
  text-align: justify;
  padding: 0 15px;
}

#model3 .social-media {
  position: absolute;
  background-color: rgba(8, 61, 119);
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding: 10px 0;
  justify-content: space-around;
  transition: all 1s;
  width: 0;
  opacity: 0;
  border-radius: 0 0 15px 15px;
  color: #fff;
}

#model3 .description .social-media svg {
  font-size: 30px;
  transition: all 1s;
}

#model3 .member:hover .social-media {
  width: 100%;
  opacity: 1;
}

#model3 .member:hover {
  background-color: rgba(8, 61, 119, 0.2);
  transform: scale(1.01);
}

#model3 .member:hover img {
  transform: scale(1.1);
}

#model3 .description .social-media svg:hover {
  transform: scale(1.1);
  color: #f21b3f;
}

#model3 .member:hover::before {
  width: 50%;
}

#model3 .member:hover::after {
  width: 50%;
}
