.effect {
  text-align: center;
  /* The image used */
  background-image: url("../../pic/oil-scaled.webp");
  /* Set a specific height */
  height: 100%;
  padding: 10rem;
  box-sizing: border-box;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 50rem;
  background-size: cover;
  background-size: 50% 100%;
}
.parallax {
  background-image: url("../../pic/oil-scaled.webp");
  background-position: left;
  background-color: #2d2402; /* Use background-color instead of 'background' for the overlay color */
  overflow: hidden;
  z-index: 2;
}

.parallax1 {
  /* The image used */
  background-image: url("../../pic/steamhom-scaled.webp");
  background-position: right;
}
.parallax2 {
  text-align: center;
  /* The image used */
  background-image: url("../../pic/IMG_4142-scaled.webp");
  background-position: left;
}
.parallax3 {
  text-align: left;
  color: white;
  /* The image used */
  background-image: url("../../pic/IMG_4109-scaled.webp");
  /* Set a specific height */
  background-position: right;
}
.parallax4 {
  text-align: center;
  /* The image used */
  background-image: url("../../pic/service-6.webp");
  background-position: left;
}
.parallax5 {
  /* The image used */
  background-image: url("../../pic/IMG_1845-scaled.webp");
  background-position: right;
  /* Set a specific height */
}

@media only screen and (max-width: 56.25em) {
  .effect {
    background-size: cover;
    background-position: center;
    background-attachment: unset;
  }
  .parrlex {
    font-size: 12px !important;
  }
  .header {
    font-size: 22px !important;
  }
}
