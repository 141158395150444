.cardcontainer {
  /* border-radius: 15px; */
  border-radius: 15px;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  width: 35rem;
  height: 220px;
}
.cardcontainer1 {
  background-image: url("../../pic/workforce-scaled.webp");
}
.midd {
  font-size: 18px;
  margin-top: 7rem;
}

.cardcontainer2 {
  background-image: url("../../pic/Reliable-Operations-scaled.webp");
}
.cardcontainer3 {
  background-image: url("../../pic/Giant-field-Photo-croped-1.webp");

  color: white;
}
.stack {
  text-align: center;
  margin-top: -15rem;
  margin-left: 5rem;
  margin-right: 5rem;
  z-index: 100;
  position: relative;
}
.p2 {
  font-size: 16px;
}

@media screen and (max-width: 420px) {
  .midd {
    font-size: 16px;
    padding-top: 0;
    margin-top: 2rem;
  }
  .cardcontainer {
    border-radius: 15px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    height: 15rem;
    width: 25rem;
  }
  .stack {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: -8rem;
  }

  .p2 {
    font-size: 10px;
  }
}
