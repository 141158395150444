.card {
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  /* border-radius: 15px; */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  height: 400px;
  width: auto;
  background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),
    url("../pic/Scimitar Background_Med..webp");
}
