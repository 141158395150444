.cont {
  text-align: center;
  /* border-radius: 15px; */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  min-height: 60rem;
  width: auto;
  background-image: url("../../pic/IMG_4155-scaled.webp");
}
.text {
  color: white;
  font-size: 4.9rem;
}
.headtitle {
  color: #cb9903;
  font-size: 16px;
}
@media only screen and (max-width: 50em) {
  .headtitle {
    font-size: 18px;
  }
}
