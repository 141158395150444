.card {
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  /* border-radius: 15px; */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  height: 650px;
  width: auto;
  background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),
    url("../../pic/Scimitar Background_Med..webp");
  position: relative;
  z-index: 1;
}

.center {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  max-width: 100%;
  padding-inline: 2rem;
}

.h2h {
  text-align: left;
  margin: 0;
  padding: 0;
  font-size: 5rem;
}
.h1h {
  text-align: left;
  display: flex;
  margin: 0;
  padding: 0;
  padding-left: 3rem;
  font-size: 12rem;
  color: #ffffff;
}

.ps {
  text-align: left;
  display: block;
  margin: 0;
  padding: 0;
}
@media only screen and (max-width: 50em) {
  .h1h {
    flex-direction: column;
    font-size: 7rem;
  }
  .h2h {
    font-size: 2.5rem;
  }
}
