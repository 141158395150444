.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #cb9903;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
a {
  text-decoration: none;
  color: inherit;
}
.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.dropbtn {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown.w-100 {
  width: 100%;
}
.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 2.5rem;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #000000;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content.right {
  left: 200px;
  top: -12px;
}

.dropdown-content a {
  background-color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  color: #ccc;
}

.dropdown-content a:hover {
  color: #fff;
}

.dropdown:hover > .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #ffbe32;
}
.navbar {
  font-size: 15px;
  font-weight: 600px;
}
