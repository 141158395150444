.aboutt {
  margin: 0;
  width: 100%;
  text-align: center;
  padding-top: 15%;
  /* The image used */
  background-image: url("../../pic/oil-scaled.webp");
  /* Set a specific height */
  min-height: 400px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.text {
  color: white;
  text-align: center;
}

.hover02 {
  padding-left: 24%;
  padding-top: 3%;
}
