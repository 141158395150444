.p1 {
  font-size: 18px;
  max-width: 80%;
  margin-top: 1rem;
  text-align: left;
}
.h21 {
  font-size: 36px;
  margin: 0px;
  padding: 0px;
  margin-left: 180px;
}
.paper {
  margin-top: -18rem;
  height: 60rem;
  flex-grow: 1;
}

.mainGrid {
  padding-top: 130px;
  text-align: center;
}
.button1 {
  font-size: 15px !important;
}
@media screen and (max-width: 420px) {
  .p1 {
    font-size: 10px;
    max-width: 80%;
  }
  .h21 {
    font-size: 24px;
    margin: 0px;
    padding: 0px;
  }
  .button1 {
    font-size: 10px !important;
  }
  .paper {
    height: 50rem;
  }
}
